<template>
	<div v-if="isLoading" class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
	<div v-else>
		<div class="tabs flex mb-10 gap-4">
			<div v-for="tab in tabs" :key="tab.value">
				<div
					:class="{ 'tab-selected': tabSelected == tab.value }"
					class="tab flex items-center justify-center cursor-pointer"
					@click="tabSelected = tab.value"
				>
					{{ tab.name }}
				</div>
			</div>
		</div>
		<div
			v-if="skill_count"
			class="p-6 bg-white shadow-sm rounded-2xl mb-10"
		>
			<div>
				<div class="flex items-center justify-between mb-6">
					<div class="font-semibold text-neutral-600">Thống kê</div>
					<div
						class="flex justify-end items-center"
						v-if="tabSelected == 0"
					>
						<Menu
							v-if="!is_old_data"
							as="div"
							class="ml-3 relative"
						>
							<div>
								<MenuButton
									class="
										rounded-full
										flex
										text-sm
										focus:outline-none
										focus:ring-2
										focus:ring-offset-2
										focus:ring-indigo-500
									"
								>
									<span class="sr-only">Open user menu</span>
									<svg
										fill="none"
										height="20"
										viewBox="0 0 20 20"
										width="20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											clip-rule="evenodd"
											d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
											fill="#94A3B8"
											fill-rule="evenodd"
										/>
									</svg>
								</MenuButton>
							</div>
							<transition
								enter-active-class="transition ease-out duration-200"
								enter-from-class="transform opacity-0 scale-95"
								enter-to-class="transform opacity-100 scale-100"
								leave-active-class="transition ease-in duration-75"
								leave-from-class="transform opacity-100 scale-100"
								leave-to-class="transform opacity-0 scale-95"
							>
								<MenuItems
									class="
										origin-top-right
										absolute
										right-0
										mt-2
										w-60
										rounded-md
										py-1
										bg-white
										focus:outline-none
										z-10
										shadow-sm
										border border-yellow-100
									"
								>
									<MenuItem
										v-slot="{ active }"
										@click="
											$router.push(
												`/create-group/0?student_id=${id}&type=library`
											)
										"
									>
										<div
											:class="active ? '' : ''"
											class="
												p-2
												px-4
												bg-white
												z-10
												text-gray-600
												hover:bg-neutral-50
												flex
												items-center
												gap-4
												cursor-pointer
											"
										>
											<svg
												fill="none"
												height="16"
												viewBox="0 0 16 16"
												width="16"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													clip-rule="evenodd"
													d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
													fill="#94A3B8"
													fill-rule="evenodd"
												/>
											</svg>
											<span class="mt-0.5"
												>Tạo nhóm mới</span
											>
										</div>
									</MenuItem>
									<!-- <MenuItem
										v-slot="{ active }"
										@click="
											$router.push(
												`/create-goal?student_id=${id}`
											)
										"
									>
										<div
											:class="active ? '' : ''"
											class="
												p-2
												px-4
												bg-white
												z-10
												text-gray-600
												hover:bg-neutral-50
												flex
												items-center
												gap-4
												cursor-pointer
											"
										>
											<svg
												fill="none"
												height="16"
												viewBox="0 0 16 16"
												width="16"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													clip-rule="evenodd"
													d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
													fill="#94A3B8"
													fill-rule="evenodd"
												/>
											</svg>
											<span class="mt-0.5"
												>Tạo mục tiêu chung</span
											>
										</div>
									</MenuItem> -->
									<MenuItem
										v-slot="{ active }"
										@click="redirectCreatePage"
									>
										<div
											:class="active ? '' : ''"
											class="
												p-2
												px-4
												bg-white
												z-10
												text-gray-600
												hover:bg-neutral-50
												flex
												items-center
												gap-4
												cursor-pointer
											"
										>
											<svg
												fill="none"
												height="16"
												viewBox="0 0 16 16"
												width="16"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													clip-rule="evenodd"
													d="M12.4705 6.65175L13.7105 5.41172C13.8463 5.27586 13.9143 5.20793 13.9669 5.14597C14.6011 4.39929 14.6011 3.30311 13.9669 2.55644C13.9143 2.49448 13.8463 2.42655 13.7105 2.29069C13.5746 2.15482 13.5067 2.08689 13.4447 2.03426C12.6981 1.40004 11.6019 1.40004 10.8552 2.03426C10.7933 2.08688 10.7253 2.15479 10.5895 2.29059L10.5895 2.29067L10.5894 2.29069L9.33123 3.5489C10.0749 4.84309 11.1586 5.91837 12.4705 6.65175ZM7.87657 5.00356L2.86297 10.0172C2.43791 10.4422 2.22538 10.6547 2.08565 10.9158C1.94591 11.1769 1.88697 11.4717 1.76908 12.0611L1.37342 14.0394C1.3069 14.372 1.27364 14.5383 1.36825 14.6329C1.46286 14.7275 1.62916 14.6943 1.96177 14.6277L3.94005 14.2321L3.94007 14.2321L3.94009 14.2321C4.52951 14.1142 4.82423 14.0553 5.08532 13.9155C5.34641 13.7758 5.55894 13.5633 5.98398 13.1382L5.984 13.1382L5.98401 13.1382L11.012 8.11019C9.75135 7.31198 8.68226 6.25011 7.87657 5.00356Z"
													fill="#94A3B8"
													fill-rule="evenodd"
												/>
											</svg>

											<span class="mt-0.5"
												>Chỉnh sửa</span
											>
										</div>
									</MenuItem>
								</MenuItems>
							</transition>
						</Menu>
					</div>
				</div>
			</div>
			<div
				:class="
					total_group_count < 1
						? ' md:grid-cols-2'
						: ' md:grid-cols-3'
				"
				class="grid grid-cols-1"
			>
				<div
					v-if="total_group_count >= 1"
					class="
						p-6
						border
						lg:rounded-l-lg
						md:rounded-tl-lg
						border-neutral-200
						md-down:rounded-t md-down:rounded-b-none
						w-full
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							alt=""
							class="mr-2"
							height="32"
							src="@/assets/images/icons/abc.png"
							style="height: 32px"
							width="32"
						/>
						{{ total_group_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số nhóm
						</span>
					</div>
				</div>
				<div
					:class="
						total_group_count < 1
							? 'lg:rounded-l-lg md:rounded-tl-lg md-down:rounded-t md-down:rounded-b-none'
							: 'md:border-l-0 md-down:border-t-0'
					"
					class="p-6 border border-neutral-200 w-full"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							alt=""
							class="mr-2"
							height="32"
							src="@/assets/images/icons/abc.png"
							style="height: 32px"
							width="32"
						/>
						{{ skill_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số lĩnh vực
						</span>
					</div>
				</div>
				<div
					class="
						p-6
						border
						md:border-l-0
						lg:rounded-r-lg
						md:rounded-br-lg
						border-neutral-200
						w-full
						md-down:border-t-0
						md-down:rounded-b
						md-down:rounded-t-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							alt=""
							class="mr-2"
							height="32"
							src="@/assets/images/icons/light.png"
							style="height: 32px"
							width="32"
						/>
						{{ goal_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số mục tiêu</span
						>
					</div>
				</div>
			</div>
		</div>
		<div v-if="tabSelected === 0">
			<div v-if="groups && groups.length > 0">
				<div
					v-if="
						groups.length > 1 ||
						groups?.[0]?.id !== 'no_have_group'
					"
				>
					<div
						v-for="(group, index) in groups"
						:key="group.id"
						class="mb-8"
					>
						<GroupDetailFull
							:data="group"
							:index="index"
							type="library"
							:tabSelected="tabSelected"
							@refresh="listGroup"
						/>
					</div>
				</div>
				<div v-else>
					<div
						v-for="(group, index) in groups?.[0]
							?.goals_group_by_skill"
						:key="group.id"
					>
						<GoalListFull
							:tabSelected="tabSelected"
							:data="group"
							:index="index"
							:student-id="Number(id)"
							class="mb-6"
							type="library"
						/>
					</div>
				</div>
			</div>
			<div
				v-else
				class="w-full h-full flex items-center justify-center flex-col"
			>
				<img
					alt=""
					height="250"
					src="@/assets/images/illustrations/not_found.png"
					width="250"
				/>
				<div
					class="text-neutral-500 text-lg text-center"
					style="max-width: 700px"
				>
					Học sinh chưa có mục tiêu trong ngân hàng mục tiêu. Đây là
					nơi lưu trữ/ tập hợp các mục tiêu bạn dự định sẽ dạy trong
					tương lai (mục tiêu dài hạn, cơ bản, 3 - 6 tháng). Khi lên
					kế hoạch dự kiến, bạn có thể chọn mục tiêu từ Ngân hàng mục
					tiêu.
				</div>
				<div class="mt-8">
					<button
						v-if="!is_old_data"
						class="button-p btn--ghost w-fit"
						type="button"
						@click="redirectCreatePage"
					>
						<span class="ml-2 whitespace-nowrap"
							>Thêm mục tiêu</span
						>
					</button>
				</div>
			</div>
		</div>
		<div v-if="tabSelected === 1">
			<div v-if="groups.length > 0">
				<TwoLayerGoal
					:data="groups"
					:index="index"
					:student-id="Number(id)"
					@refresh="listTwoLayerGoal"
				/>
			</div>
			<div
				v-else
				class="w-full h-full flex items-center justify-center flex-col"
			>
				<img
					alt=""
					height="250"
					src="@/assets/images/illustrations/not_found.png"
					width="250"
				/>
				<div
					class="text-neutral-500 text-lg text-center"
					style="max-width: 700px"
				>
					Học sinh chưa có mục tiêu trong ngân hàng mục tiêu. Đây là
					nơi lưu trữ/ tập hợp các mục tiêu bạn dự định sẽ dạy trong
					tương lai (mục tiêu dài hạn, cơ bản, 3 - 6 tháng). Khi lên
					kế hoạch dự kiến, bạn có thể chọn mục tiêu từ Ngân hàng mục
					tiêu.
				</div>
				<div class="mt-8">
					<button
						v-if="!is_old_data"
						class="button-p btn--ghost w-fit"
						type="button"
						@click="redirectCreatePage"
					>
						<span class="ml-2 whitespace-nowrap"
							>Thêm mục tiêu</span
						>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { _ROUTER_NAME } from '@/enums';
import router from '@/router';
import { useStore } from 'vuex';
import Loading from '@/components/common/Loading.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { $api } from '@/services';
import GoalListFull from '@/components/elements/GoalListFull.vue';
import GroupDetailFull from '@/components/modules/groups/GroupDetailfull.vue';
import TwoLayerGoal from '@/components/elements/TwoLayerGoal.vue';

const route = useRoute();
const store = useStore();
const groups = ref([]);
const skill_count = ref(0);
const goal_count = ref(0);
const total_group_count = ref(0);
const isLoading = ref(false);
const tabs = ref([
	{
		name: 'Ngân hàng mục tiêu',
		value: 0,
	},
	{
		name: 'Quản lý mục tiêu chung',
		value: 1,
	},
]);
const tabSelected = ref(0);

const id = computed(() => (route.params?.id ? route.params?.id + '' : ''));

const is_old_data = ref(route.query.is_old_data === 'true');

onMounted(() => {
	listGroup();
});

watch(tabSelected, () => {
	if (tabSelected.value == 0) listGroup();
	else listTwoLayerGoal();
});

async function listGroup() {
	try {
		isLoading.value = true;
		const {
			data: { data, stats },
		} = await $api.goal.getListGroupWithDetailInLibs(id.value);
		groups.value = data || [];
		if (stats) {
			skill_count.value = stats?.total_skill_count || 0;
			goal_count.value = stats?.total_goal_count || 0;
			total_group_count.value = stats?.total_group_count || 0;
		}
	} catch (err) {
		await store.dispatch('setAlertMessage', {
			message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	} finally {
		isLoading.value = false;
	}
}

async function listTwoLayerGoal() {
	try {
		isLoading.value = true;
		const {
			data: { data, stats },
		} = await $api.goal.getTwoLayerListGroupWithDetailInLibs(id.value);
		groups.value = data?.skills || [];
		if (stats) {
			skill_count.value = stats?.skills_count || 0;
			goal_count.value = stats?.goals_count || 0;
			total_group_count.value = stats?.group_count || 0;
		}
	} catch (err) {
		await store.dispatch('setAlertMessage', {
			message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	} finally {
		isLoading.value = false;
	}
}

function redirectCreatePage() {
	router.push({
		name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_LIB__CREATE,
		params: {
			id: id.value,
		},
	});
}
</script>
<style scoped>
.tab {
	padding: 8px 12px 8px 12px;
	color: #48566a;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.tab-selected {
	border-radius: 8px;
	background: #e5a42b;
	box-shadow: 0px 3px 8px -1px #3232470d;
	color: #fff;
}
</style>
